import confirmRemovePopup from '@/components/popups/remove-confirm/index.vue'
import {mapGetters, mapMutations} from "vuex";

export default {
    components: {
      confirmRemovePopup
    },
    props:['selectedItem', 'uniteOrders', 'shipmentPrint', 'timerStatus', 'canAddOrder', 'filterList'],
    data(){
      return{
          toggleOptions: false,
          showConfirmPopup: false,
          timerTime: 60,
          saveTimerTime: 60,
          timerToggleLocal: false,
          filterListName:{
              'shipment_method_id':'Тип доставки',
              'payment_method_id':'Оплата',
              'description':'Комментарий',
              'employee_id':'Сотрудник',
              'comment':'Комментарий',
              'department_id':'Отдел',
              'order_id':'order_id',
              'title':'Название',
              'site':'Название',
              'domain':'domain',
              'phone':'Телефон',
              'email':'email',
              'name':'Имя',
              'ttn':'TTH',
              'id':'id',
          }
      }
    },
    computed:{
      ...mapGetters({
          shipmentMethods:'orders/shipmentMethods',
          paymentMethods:'orders/paymentMethods',
          departments:'catalog/departments',
          timerToggle:'config/timerToggle',
          ordersList:'orders/ordersList',
          menuHide:'config/menuHide',
          users: 'contacts/users',

      })
    },
    watch:{
        timerToggleLocal(e){
            this.changeTimerToggle(e);
        },
        timerToggle(e){
            if(e){
                this.timerBegin()
            }
        },
    },
    methods:{
        removeFilterItem(index){
          this.$emit('removeFilterItem', index)
        },
        getFilterNameType(typeName){
            let result, key;
            for(key in this.filterListName){
                if(key === typeName){
                    result = this.filterListName[key]
                }
            }
          return  result;
        },
        getFilterName(item){
            let resultName;
            if(item.type === 'employee_id') {
                resultName = this.users.filter(userItem => +userItem.id === Number(item.query))
                resultName = resultName[0].name
            }else if(item.type === 'payment_method_id') {
                resultName = this.paymentMethods.data.filter(userItem => +userItem.id === Number(item.query))
                resultName = resultName[0].title
            }else if(item.type === 'shipment_method_id') {
                resultName = this.shipmentMethods.data.filter(userItem => +userItem.id === Number(item.query))
                resultName = resultName[0].title
            }else if(item.type === 'department_id') {
                resultName = this.departments.data.filter(userItem => +userItem.id === Number(item.query))
                resultName = resultName[0].title
            }else{
                resultName = item.query
            }
            return resultName
        },
        validateTimer(){
            if(this.timerTime < 30){
                this.timerTime = 30;
                this.$toasted.error('Минимальное время обновления 30 секунд', {
                    duration: 3000
                })
            }
            this.saveTimerTime = this.timerTime;
        },
        timerBegin(){
            if(this.timerTime >= 1 && this.timerToggle){
                setTimeout(()=>{
                    this.timerTime--;
                    this.timerBegin();
                },1000)
            }else{
                if(this.timerToggle){
                    this.timerTime = this.saveTimerTime;
                    this.$emit('timerReady')
                    this.timerBegin();
                }else{
                    this.timerTime = this.saveTimerTime;
                }
            }
        },
        openOptions(){
            if(this.selectedItem){
                this.toggleOptions = true;
            }
        },
        setUniteOrders(){
          this.$emit('setUniteOrders')
        },
        openAddPopup(){
          this.$emit('openAddPopup');
        },
        changeItem(){
          if(this.selectedItem.length > 1) {
              return
          }
          this.$emit('changePopup', this.selectedItem[0]);
        },
        removeItem(){
            this.toggleOptions = false;
            console.log('removeItem', this.selectedItem)
            this.$emit('removeItem',this.selectedItem[0].id );
            this.showConfirmPopup = false
        },
        getAllItemsInTable(){
            this.$emit('getAllItemsInTable');
        },
        ...mapMutations({
            changeTimerToggle: 'config/changeTimerToggle',
        })
    }
}